import React from 'react'
import Homesec1 from './Homesec1/Homesec1'
import Homesec2 from './Homesec2/Homesec2'
import HomeThird from './HomeThird/HomeThird'
import Homeindus from './Homeindus/Homeindus'
import Homeacci from './HomeAcc/Homeacci'
const Home = () => {
  return (
    <>
      <Homesec1/>
    <Homesec2/>
    <HomeThird/>
    <Homeindus/>
    <Homeacci/> 
    </>
  )
}

export default Home